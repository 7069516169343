import { Empleado, EmpleadoDto, ipxTurno } from "./models/empleado";
import { RegistroDto } from "./models/registro";
import { TipoAccion } from "./models/tipo-accion";

export enum DetectionTypes {
	FACE = "face",
	BARCODE = "barcode",
	BULK = "bulk",
}

export interface ScanReg {
	data: string;
	timestamp: number;
	sync: number; // false cuando no se ha sincronizado
	empleado?: Empleado;
}

export enum ScanSyncType {
	PENDIENTE = 0,
	SINCRONIZADO = 1,
	ERROR = 2,
}

export interface DetalleRegistro{
    id: number;
    media_id: number;
    fecha_hora: string;
}

export interface DetalleIngresoRegistro extends DetalleRegistro{
    tipo_registro: TipoAccion.INGRESO;
}

export interface DetalleEgresoRegistro extends DetalleRegistro{
    tipo_registro: TipoAccion.EGRESO;
}

export interface RegistroResponse {
	rta: {
		Registro: {
			Registro: RegistroDto;
			Empleado: EmpleadoDto;
			Turno: ipxTurno[];
			DetalleEgresoRegistro: DetalleEgresoRegistro;
			DetalleIngresoRegistro: DetalleIngresoRegistro;
		};
	};
}
